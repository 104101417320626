<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="left">
        <el-form inline class="search-header" :model="searchData" label-width="100px">
          <el-form-item label="客户姓名">
            <el-input style="width:300px" v-model="searchData.memberName" placeholder="请输入客户姓名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input style="width:300px" v-model="searchData.memberMobile" placeholder="请输入手机号" size="small"></el-input>
          </el-form-item>
          <el-form-item label="唯一码" v-show="isShow">
            <el-input style="width:300px" v-model="searchData.sysOrderFixSn" placeholder="请输入唯一码" size="small"></el-input>
          </el-form-item>
          <el-form-item label="账期" v-show="isShow">
            <el-date-picker
              style="width:300px"
              size="small"
              v-model="searchData.timeSlot"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="账单状态" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.billStatus" clearable placeholder="请选择" size="small">
              <el-option v-for="item in BILL_STATUS" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="未付天数" v-show="isShow">
            <el-input-number
                    size="small"
                    style="width:138px"
                    v-model="searchData.leftOverdueDays"
                    :step="1"
                    :min="0"
                    step-strictly
                    controls-position="right"
            ></el-input-number>
            <span> 至 </span>
            <el-input-number
                    size="small"
                    style="width:138px"
                    v-model="searchData.rightOverdueDays"
                    :step="1"
                    :min="0"
                    step-strictly
                    controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="平台" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.sysCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in sysList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.merchantCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in merchantList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付时间" v-show="isShow">
            <el-date-picker
              style="width:300px"
              size="small"
              v-model="searchData.payTimeSlot"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="创建时间" v-show="isShow">
            <el-date-picker
                    style="width:300px"
                    size="small"
                    v-model="searchData.sysCreateTimeSlot"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="客户经理" v-show="isShow">
            <el-select size="small" style="width:300px" v-model="searchData.userName" filterable clearable placeholder="请选择">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.userName"></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item class="arrow" label=" ">
        <el-button @click="getList" type="primary" icon="el-icon-search" size="small">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
      </el-form-item> -->
        </el-form>
      </div>
      <div class="right">
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
        <el-button style="margin-left:10px" @click="getList" type="primary" icon="el-icon-search" size="small">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="btn-group">
      <div></div>
      <div>
        <el-button v-checkbtn="isShowBtn(AUTH_BTN.bill_export)" type="primary" icon="el-icon-plus" size="small" @click="Download">导出</el-button>
      </div>
    </div>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { BILL_STATUS, getDictEnumListAPI } from '@/enum/dict.js'
import { getListAPI, DownloadAPI, getAdminUserList } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '平台',
    prop: 'sysName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['sysName']}</p>
    }
  },
  {
    label: '商户',
    prop: 'merchantName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['merchantName']}</p>
    }
  },
  {
    label: '客户经理',
    prop: 'userName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['userName']}</p>
    }
  },
  {
    label: '客户姓名',
    prop: 'member',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['member']['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'memberMobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']['mobile']}</p>
    }
  },

  {
    label: '期数',
    prop: 'periodNum',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['periodNum']}</p>
    }
  },
  {
    label: '账期',
    prop: 'billDate',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['billDate']}</p>
    }
  },
  {
    label: '未付天数',
    prop: 'overdueDays',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['overdueDays'] <= -1 ? '无' : (row['overdueDays'] == 0 ? '未逾期' : row['overdueDays'] + '天')}</p>
    }
  },
  {
    label: '金额',
    prop: 'amount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['amount']}</p>
    }
  },
  {
    label: '逾期金额',
    prop: 'overdueAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['overdueAmount']}</p>
    }
  },
  {
    label: '逾期时间',
    prop: 'overdueDate',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['overdueDate']}</p>
    }
  },
  {
    label: '当前状态（系统）',
    prop: 'sysStatus',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['sysStatus']}</p>
    }
  },
  {
    label: '付款时间',
    prop: 'payTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['payTime']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'sysCreateTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['sysCreateTime']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'orderSn',
    minWidth: '190',
    customRender(h, row) {
      return <p>{row['order']?.['orderSn']}</p>
    }
  },
  {
    label: '唯一码',
    prop: 'sysOrderFixSn',
    minWidth: '190',
    customRender(h, row) {
      return <p>{row['order']?.['sysOrderFixSn']}</p>
    }
  },
  {
    label: '流水号',
    prop: 'billSn',
    minWidth: '190',
    customRender(h, row) {
      return <p>{row['billSn']}</p>
    }
  }

  // {
  //   label: '操作',
  //   width: '100',
  //   fixed: 'right',
  //   customRender(h, row) {
  //     return (
  //       <div>
  //         <el-link
  //           underline={false}
  //           type="primary"
  //           onClick={() => {
  //             this.Download()
  //           }}
  //         >
  //           导出
  //         </el-link>
  //       </div>
  //     )
  //   }
  // }
]
export default {
  name: 'List',

  mixins: [authBtnMixin],

  data() {
    return {
      isShow: false, //控制箭头
      columns,
      currentPage: 1,
      page_size: 50,
      total: 0,
      list: [],
      searchData: {
        memberName: '',
        memberMobile: '',
        sysOrderFixSn: '',
        leftBillDate: null,
        rightBillDate: null,
        leftPayDate: null,
        rightPayDate: null,
        timeSlot: [],
        userName: '',
        billStatus: '',
        sysCode: '',
        merchantCode: '',
        payTimeSlot: [],
        sysCreateTimeSlot: [],
        leftOverdueDays: undefined,
        rightOverdueDays: undefined,
      },
      BILL_STATUS,
      sysList: [],
      merchantList: [],
      userList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [start, start])
            }
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [end, end])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
    this.getDictEnumList()
    this.getAdminUserList()
  },

  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },

  methods: {
    async getList() {
      const {
        memberName,
        memberMobile,
        sysOrderFixSn,
        timeSlot,
        payTimeSlot,
        sysCreateTimeSlot,
        leftBillDate,
        rightBillDate,
        leftPayDate,
        rightPayDate,
        leftSysCreateDate,
        rightSysCreateDate,
        userName,
        billStatus,
        sysCode,
        merchantCode,
        leftOverdueDays,
        rightOverdueDays,
      } = this.searchData
      let params = {
        orderId: this.$route.query.orderId || '',
        memberName,
        memberMobile,
        sysOrderFixSn,
        leftBillDate,
        rightBillDate,
        leftPayDate,
        rightPayDate,
        leftSysCreateDate,
        rightSysCreateDate,
        userName,
        billStatus,
        sysCode,
        merchantCode,
        leftOverdueDays,
        rightOverdueDays,
        page: this.currentPage,
        pageSize: this.page_size
      }
      params.leftBillDate = timeSlot?.[0] || undefined
      params.rightBillDate = timeSlot?.[1] || undefined
      params.leftPayDate = payTimeSlot?.[0] || undefined
      params.rightPayDate = payTimeSlot?.[1] || undefined
      params.leftSysCreateDate = sysCreateTimeSlot?.[0] || undefined
      params.rightSysCreateDate = sysCreateTimeSlot?.[1] || undefined
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    Download() {
      const {
        memberName,
        memberMobile,
        sysOrderFixSn,
        timeSlot,
        payTimeSlot,
        sysCreateTimeSlot,
        leftBillDate,
        rightBillDate,
        leftPayDate,
        rightPayDate,
        leftSysCreateDate,
        rightSysCreateDate,
        userName,
        billStatus,
        sysCode,
        merchantCode,
      } = this.searchData
      let params = {
        orderId: this.$route.query.orderId || '',
        memberName,
        memberMobile,
        sysOrderFixSn,
        leftBillDate,
        rightBillDate,
        leftPayDate,
        rightPayDate,
        leftSysCreateDate,
        rightSysCreateDate,
        userName,
        billStatus,
        sysCode,
        merchantCode,
      }
      params.leftBillDate = timeSlot?.[0] || undefined
      params.rightBillDate = timeSlot?.[1] || undefined
      params.leftPayDate = payTimeSlot?.[0] || undefined
      params.rightPayDate = payTimeSlot?.[1] || undefined
      params.leftSysCreateDate = sysCreateTimeSlot?.[0] || undefined
      params.rightSysCreateDate = sysCreateTimeSlot?.[1] || undefined
      DownloadAPI(params)
    },
    async getDictEnumList() {
      this.sysList = await getDictEnumListAPI('identity:sys')
      this.merchantList = await getDictEnumListAPI('identity:merchant')
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    async getAdminUserList() {
      const res = await getAdminUserList({})
      this.userList = res || []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
    }
    .right {
      margin-left: 20px;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      // text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 40%;
      min-width: 400px;
    }
  }
  .el-divider {
    margin: 10px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-date-editor /deep/ {
    .el-range-separator {
      padding: 0;
    }
  }
}
</style>
